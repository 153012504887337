import React from 'react'
import { RichText } from 'prismic-reactjs'
import { StaticQuery, graphql } from 'gatsby'

import {
  Box,
  DisplayText,
  FlexCol,
  FlexGrid,
  H2,
  H3,
  Highlight,
  SEO,
  Text,
} from 'src/components'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        contact: prismicContactPage {
          dataString
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.contact.dataString)
      return (
        <>
          <SEO
            title={
              page.opengraph_title ? RichText.asText(page.opengraph_title) : ''
            }
            description={
              page.opengraph_description
                ? RichText.asText(page.opengraph_description)
                : ''
            }
            image={page.opengraph_image ? page.opengraph_image.url : ''}
          />
          <Box px={[3, null, null, 4]} py={4} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <FlexCol alignItems="center">
                {page.hero_header && (
                  <DisplayText
                    mb="0.5em"
                    textAlign="center"
                    style={{ transform: 'rotate(-7.5deg)' }}
                  >
                    <Highlight variant={1}>
                      {RichText.asText(page.hero_header)}
                    </Highlight>
                  </DisplayText>
                )}
                {page.hero_subheader && (
                  <Box maxWidth={512} mx="auto">
                    <H2
                      children={RichText.asText(page.hero_subheader)}
                      textAlign="center"
                    />
                  </Box>
                )}
              </FlexCol>
            </Box>
          </Box>
          {page.chapters && (
            <Box bg="bg.default">
              <FlexGrid>
                {page.chapters.map((chapter, index) => (
                  <FlexCol
                    justifyContent="center"
                    alignItems="center"
                    width={[1, null, 1 / 2]}
                    bg={index % 2 === 0 ? 'bg.wash' : 'bg.alt'}
                  >
                    <Box
                      mx={[3, 4]}
                      pl={
                        index % 2 === 0
                          ? 'calc((100vw / 2) - (1280px / 2))'
                          : ''
                      }
                      pr={
                        index % 2 === 1
                          ? 'calc((100vw / 2) - (1280px / 2))'
                          : ''
                      }
                      py={4}
                    >
                      {chapter.chapter_title && (
                        <H3
                          children={RichText.asText(chapter.chapter_title)}
                          mb="1em"
                          textAlign="center"
                        />
                      )}
                      {chapter.chapter_address && (
                        <Text mb="1em" textAlign="center">
                          {RichText.render(
                            chapter.chapter_address,
                            linkResolver,
                            serializer
                          )}
                        </Text>
                      )}
                      <Text textAlign="center">
                        {chapter.chapter_email && (
                          <a
                            children={chapter.chapter_email}
                            href={'mailto:' + chapter.chapter_email}
                          />
                        )}
                        <br />
                        {chapter.chapter_phone_number && (
                          <a
                            children={chapter.chapter_phone_number}
                            href={'tel:' + chapter.chapter_phone_number}
                          />
                        )}
                      </Text>
                    </Box>
                  </FlexCol>
                ))}
              </FlexGrid>
            </Box>
          )}
        </>
      )
    }}
  />
)

export default Contact
